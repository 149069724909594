import { ChevronDownIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { HTMLAttributes, useEffect } from 'react';

export enum InputVariants {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Phone = 'phone',
  Number = 'number',
  Filled = 'filled',
}

export interface InputProps
  extends Pick<
    React.ComponentProps<'input'>,
    | 'maxLength'
    | 'onFocus'
    | 'onBlur'
    | 'name'
    | 'placeholder'
    | 'disabled'
    | 'autoComplete'
    | 'type'
    | 'value'
    | 'onClick'
    | 'onPaste'
    | 'id'
    | 'autoComplete'
  > {
  label?: string;
  onChange?: (val: string) => void;
  step?: number;
  float?: number;
  invalid?: boolean;
  prefixOption?: {
    value: string;
    options: { label: string; value: string }[];
    setPrefixOption: (value: string) => void;
  };
  suffixOption?: {
    value: string;
    options: { label: string; value: string }[];
    setSuffixOption: (value: string) => void;
  };
  prepend?: string | React.ComponentType<HTMLAttributes<SVGElement>>;
  append?: string | React.ComponentType<HTMLAttributes<SVGElement>>;
  onClickAppend?: () => void;
  'aria-label': string;
}
interface DropDownAppendageProps extends Pick<InputProps, 'value'> {
  keyPrefix: string;
  prefix: boolean;
  disabled: boolean;
  isFocused: boolean;
  setFocused: (value: boolean) => void;
  options: { label: string; value: string }[];
  setValue: (value: string) => void;
}
const DropDownAppendage: React.FC<DropDownAppendageProps> = ({
  keyPrefix,
  prefix,
  disabled = false,
  isFocused = false,
  setFocused,
  value,
  options,
  setValue,
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    document.body.style.setProperty('overflow', 'visible', 'important');
    return () => {
      document.body.style.setProperty('overflow', 'visible', 'important');
    };
  }, [open]);

  const className = `flex items-center bg-neutral-100 ${
    disabled
      ? 'pointer-events-none text-neutral-600 cursor-default'
      : 'text-neutral-800 cursor-pointer'
  }
  ${
    prefix ? 'border-r border-r-neutral-300' : 'border-l border-l-neutral-300'
  }  
  ${isFocused ? '!border-[#1E3A8A]' : ''}
  `;

  useEffect(() => {
    if (open) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [open, setFocused]);

  return (
    <div
      className={className}
      aria-label={`${prefix ? 'prefix' : 'suffix'} dropdown menu`}
    >
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger
          asChild
          role="button"
          aria-label={`${prefix ? 'prefix' : 'suffix'} dropdown button`}
        >
          <div className="flex h-full items-center px-m">
            <button aria-label="current value">{value}</button>
            <ChevronDownIcon className="ml-s h-l w-l" />
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={`h-content modal-scroll-bar z-50 flex max-h-[300px] w-full flex-col justify-between gap-xs overflow-auto rounded-small bg-neutral-0 py-xs shadow-md`}
          >
            {options.map((option, index) => (
              <DropdownMenu.Item
                aria-label={option.label}
                key={`${keyPrefix}-${option.label}-${index}`}
                className="flex w-full cursor-pointer items-center px-xs hover:!border-none focus:!border-none"
                onClick={(e) => {
                  setValue(option.value);
                  setOpen(false);
                  setFocused(false);
                  e.preventDefault();
                }}
              >
                <div
                  className={`flex w-full rounded-xsmall px-m py-[9px] hover:bg-neutral-200 ${option.value === value ? '!bg-blue-light' : ''}`}
                >
                  {option.label}
                </div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export const Input: React.FC<InputProps> = ({
  id,
  type = InputVariants.Text,
  onChange = () => {},
  onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('text').trim();
    if (Number.isNaN(pasteData) || Number(pasteData) < 0) {
      e.preventDefault();
    }
  },
  step,
  float = -1,
  onClick = () => {},
  label,
  prefixOption,
  suffixOption,
  invalid = false,
  prepend: Prepend,
  append: Append,
  onClickAppend,
  value,
  ...props
}) => {
  const [isFocused, setIsFocused] = React.useState(false);

  let borderColor = 'border-neutral-300';
  if (isFocused) {
    borderColor = 'border-brandMidnight';
  }
  if (invalid) {
    borderColor = 'border-red-medium';
  }
  if (type === InputVariants.Filled) {
    borderColor = 'border-none';
  }

  const prependString = typeof Prepend === 'string';
  const prependIcon = Prepend && typeof Prepend !== 'string';
  const appendString = typeof Append === 'string';
  const appendIcon = Append && typeof Append !== 'string';

  return (
    <div
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={`flex w-full flex-col ${
        props.disabled
          ? 'pointer-events-none text-neutral-400'
          : 'text-neutral-800'
      }`}
    >
      <div className="flex flex-col">
        {label && (
          <label
            className={`pb-s text-body2Regular ${
              props.disabled ? 'text-neutral-600' : ''
            }`}
          >
            {label}
          </label>
        )}
        <div
          className={`relative inline-flex min-w-[140px] overflow-hidden rounded-xsmall border ${type === InputVariants.Filled ? 'bg-transparent' : 'bg-neutral-0'} ${borderColor}`}
        >
          {prefixOption && (
            <DropDownAppendage
              keyPrefix={`prefixOption-${id}`}
              prefix={true}
              disabled={props?.disabled || false}
              isFocused={isFocused}
              setFocused={setIsFocused}
              value={prefixOption.value}
              options={prefixOption.options}
              setValue={prefixOption.setPrefixOption}
            />
          )}

          <div
            className={`relative flex w-full items-center py-m text-body1Light ${
              type === InputVariants.Filled ? '' : 'px-m'
            }`}
          >
            {prependIcon && (
              <Prepend
                className={`absolute left-m flex ${
                  !value || String(value).length === 0
                    ? 'text-neutral-600'
                    : 'text-neutral-800'
                } size-l`}
              />
            )}
            {prependString && (
              <div
                className={`absolute left-m flex ${
                  !value || String(value).length === 0 ? 'text-neutral-600' : ''
                }`}
              >
                {Prepend}
              </div>
            )}
            {appendIcon && (
              <Append
                className={`absolute right-m flex ${
                  !value || String(value).length === 0
                    ? 'text-neutral-600'
                    : 'text-neutral-800'
                } size-l`}
                onClick={onClickAppend}
              />
            )}
            {appendString && (
              <div
                className={`absolute right-m flex ${
                  !value || String(value).length === 0 ? 'text-neutral-600' : ''
                }`}
                onClick={onClickAppend}
              >
                {Append}
              </div>
            )}
            <div
              className={`inline-flex w-full ${
                prependString ? 'pl-m' : ''
              } ${prependIcon ? 'pl-xl' : ''} ${
                appendString ? 'pr-m' : ''
              } ${appendIcon ? 'pr-xl' : ''}`}
            >
              {type === InputVariants.Filled ? (
                <>{value}</>
              ) : (
                <input
                  onClick={onClick}
                  type={type}
                  onChange={(evt) => {
                    if (float > -1 && type === InputVariants.Number) {
                      const newValue = Number(evt.currentTarget.value).toFixed(
                        float
                      );
                      onChange(newValue);
                    } else {
                      onChange(evt.currentTarget.value);
                    }
                  }}
                  className="no-buttons flex w-full placeholder-neutral-600"
                  onPaste={onPaste}
                  step={step}
                  value={value}
                  {...props}
                  disabled={false}
                />
              )}
            </div>
          </div>
          {suffixOption && (
            <DropDownAppendage
              keyPrefix={`suffixOption-${id}`}
              prefix={false}
              disabled={props?.disabled || false}
              isFocused={isFocused}
              setFocused={setIsFocused}
              value={suffixOption.value}
              options={suffixOption.options}
              setValue={suffixOption.setSuffixOption}
            />
          )}
        </div>
      </div>
    </div>
  );
};
